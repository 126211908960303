<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%; max-height: 90vh;" class="mx-auto">
        <v-row>
          <v-card-title class="font-weight-bold pb-3">
            {{ $t('title.contractor.explanImportantMatters') }}
          </v-card-title>
        </v-row>
        <v-row class="pl-4">
          <p>{{ $t('description.explanImportantMatters.explanation1') }}</p>
        </v-row>
        <v-row class="pl-4">
          <p class="mb-0">
            {{ $t('description.explanImportantMatters.explanation2') }}
          </p>
        </v-row>
        <v-row class="pl-4">
          <p class="my-0">
            {{ $t('description.explanImportantMatters.explanation3') }}
          </p>
        </v-row>
        <v-row class="pl-4">
          <p class="mt-0">
            {{ $t('description.explanImportantMatters.explanation4') }}
          </p>
        </v-row>
        <v-row class="pl-4">
          <p>{{ $t('description.explanImportantMatters.explanation5') }}</p>
        </v-row>
        <v-row>
          <v-btn
            class="font-weight-black mx-auto"
            align="center"
            @click="download"
            max-width="400px"
            width="85vw"
            color="download"
            style="font-size:16px"
            dark
            rounded
            >{{ $t('button.download') }}</v-btn
          >
        </v-row>
        <v-row class="pt-8">
          <div class="text-center" style="margin: 0 auto;">
            <input
              :disabled="checkDisabled"
              type="checkbox"
              id="checkbox"
              v-model="checked"
            />
            <label for="checkbox">{{ $t('checkbox.agree') }}</label>
          </div>
        </v-row>
        <v-row class="my-12">
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              @click="backPage"
              width="30vw"
              max-width="170px"
              color="back"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black white--text mx-auto"
              align="center"
              :disabled="!checked"
              @click="goNextPage"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:20px"
              rounded
              >{{ $t('button.next') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { getExplanationsForImportantMatter } from '@/apis/contractor/contracts';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    stepPoint: Number,
    docs: Array,
  },
  data: () => ({
    checked: false,
    checkDisabled: true,
  }),
  async mounted() {
    this.checkDisabled = !this.downloadDoc();
    this.checked = this.isAgreeChecked();
  },
  methods: {
    ...mapActions('contract', [
      'setDownloadDoc',
      'setPendingStep',
      'setIsAgreeChecked',
    ]),
    ...mapGetters('contract', [
      'downloadDoc',
      'pendingStep',
      'isAgreeChecked',
      'pageSum',
      'needUpload',
    ]),
    async download() {
      const response = await getExplanationsForImportantMatter();
      if (response instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      if (response) {
        let link = document.createElement('a');

        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        let isIos = false;
        // iOS機種であるかを判別
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          isIos = true;
        }
        if (isIos) {
          link.href = window.URL.createObjectURL(
            new Blob([response], { type: 'application/octet-stream' })
          );
        } else {
          link.href = window.URL.createObjectURL(
            new Blob([response], { type: 'application/pdf' })
          );
        }

        link.download = '重要事項等説明書.pdf';
        link.click();
      }
      if (this.checkDisabled) {
        this.checkDisabled = false;
      }
      this.setDownloadDoc(true);
    },
    backPage() {
      this.setIsAgreeChecked(this.checked);
      const path = this.needUpload()
        ? '/check_need_upload#upload_required_documents'
        : `/review_contract/${this.pageSum()}`;
      this.$router.push({
        path: path,
        params: { docs: this.docs },
      });
    },
    goNextPage() {
      const nextStep = this.stepPoint + 1;
      if (this.pendingStep() < nextStep) this.setPendingStep(nextStep);
      this.setIsAgreeChecked(true);
      this.$router.push({
        path: '/check_need_upload#final_review_contract',
        name: 'CheckNeedUploadFinalReviewContract',
        params: { attentedDocs: this.docs },
      });
    },
  },
};
</script>
